import React from "react";
// import { Link } from "gatsby";
import Layout from "../components/layout";
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';


const Magda = () => {
    return (
        <Layout>
            <Seo title="Magdalena Sobieska"/>
            <section className="member-container">
                <section className="member-intro">
                    <div className="member-intro_text">
                        <h1 className="member-intro_text_name">Magdalena Sobieska</h1>
                        <p className="member-intro_text_description">Dyplomowana instruktorka Pilatesu oraz Stretchingu, Psycholog i Choreoterapeuta w Edukacji
                        </p>
                    </div>
                    <div className="member-intro_picture-container">
                        <StaticImage 
                            src='../images/poza_magda.jpg' 
                            placeholder="blurred"
                            loading="eager"
                            alt="instruktorka Magdalena Sobieska" 
                            className='member-intro_picture'
                        />
                    </div>
                </section>
                <section className="member-hero">
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">O MAGDALENIE</h2>
                        </div>
                        <div className="member-hero-paragraph_info">
                            <h3 className="member-hero-paragraph_info-bold">Dyplomowana instruktorka Pilatesu oraz Stretchingu, Psycholog i Choreoterapeuta w Edukacji, aktorka teatru tańca, tancerka
                            </h3>
                            <br/>
                            <p className="member-hero-paragraph_info-text">Magda pracuje metodą Pilates w nurcie współczesnym.</p>
                            <p className="member-hero-paragraph_info-text">Ruch i taniec towarzyszy jej od dziecka. To właśnie na lekcji tańca poznała metodę Pilates. Ta forma ruchu całkowicie ją oczarowała. Podczas wielu lat praktyki różnych form tańca, stretchingu i jogi zdobywała wiedzę i doświadczenie w obszarze pracy z ciałem. Jest certyfikowanym Instruktorem metod Pilates oraz Stretching. Do swoich zajęć lubi dodawać różne formy ruchu elementy tańca czy relaksacja.</p>
                            <p className="member-hero-paragraph_info-text">Ukończyła studia magisterskie z psychologii na Uniwersytecie Kardynała Stefana Wyszyńskiego w Warszawie. Jest też absolwentką “Choreoterapii w edukacji” w Akademii Humanistyczno – Ekonomicznej w Łodzi, tancerką oraz aktorką Teatru Tańca Test.</p>    
                        </div>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">METODY</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Pilates</li>
                            <li>Stretching</li>
                            <li>Kreacja ruchu</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE ZAJĘĆ</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Zajęcia indywidualne</li>
                                <li>Zajęcia w duetach</li>
                                <li>Zajęcia w minigrupach</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE SPRZĘTU</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Reformer with Tower</li>
                            <li>Cadillac</li>
                            <li>Wunda Chair</li>
                            <li>Ladder Barrel</li>
                            <li>Spine Corrector</li> 
                            <li>Mata (oraz sprzęt pilatesowy typu : Roller, Disc, Small Ball, Heavy Ball, Ovo Ball, Body Ball, Band, Magic Circle oraz dodatkowe akcesoria sportowe, np. małe ciężarki, piłeczki do automasażu, itp. </li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">SPECJALIZACJE</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Tancerze</li>
                            <li>Osoby z wadami postawy</li>
                            <li>Osoby z urazami i dolegliwościami bólowymi</li>
                            <li>Osoby po wypadkach, urazach, operacjach</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title"></div>
                        <h4 className="member-hero-paragraph_info-bold">Zapisy na zajęcia z Magdą tylko mailowo</h4>
                        <div className="member-hero-paragraph_info_link-container">
                            <a href="mailto:magdasobieska2@gmail.com" className="member-hero-paragraph_info_link-link">ZAPISZ SIĘ</a>
                        </div>
                    </section>
                </section>
            </section>
        </Layout>
    )
}

export default Magda;